<script setup>
import { ButtonTypes } from '@@/components/Common/Button.vue';
import { parseOpenMountainApiError, safeRound } from '@@/utils/CommonUtils';
import { useUserNotificationsStore } from '@@/stores/UserNotifications';
import { useUserStore } from '@@/stores/User';

const { gtag } = useGtag();
const userNotificationsStore = useUserNotificationsStore();
const userStore = useUserStore();

const props = defineProps({
  action: {
    type: String,
    default: 'create',
    validate(value) {
      return ['create', 'update'].includes(value);
    },
  },
  alertArea: {
    type: Object,
    default: null,
  },
  location: {
    type: Object,
    default: null,
  },
});

const $emit = defineEmits(['close']);

const myAlertArea = ref({
  forecast_level: props.alertArea?.forecast_level ?? 0,
  report_level: props.alertArea?.report_level ?? 0,
});

const myLocation = ref(props.location ? { ...props.location } : null);

const isAllAccess = computed(() => userStore.isAllAccess);
const isCreateAction = computed(() => props.action === 'create');
const isUpdateAction = computed(() => props.action === 'update');

const showLocationSearch = computed(() => isCreateAction.value && myLocation.value === null);
const title = computed(() => {
  if (showLocationSearch.value) {
    return 'Select a Location';
  }

  return isCreateAction.value ? 'Create Alert' : 'Edit Alert';
});

const units = computed(() => userStore.preferences.units);

const unavailableText = computed(() => myLocation.value?.has_resort_report === false
  ? 'This location does not receive snow reports.'
  : null);

const isActionButtonDisabled = computed(() => myAlertArea.value?.forecast_level === 0
  && myAlertArea.value?.report_level === 0);

const updateButtonClass = computed(() => isCreateAction.value ? 'tw-w-full' : 'tw-w-1/2 tw-mr-2');
const deleteButtonClass = computed(() => isUpdateAction.value ? 'tw-w-1/2 tw-ml-2' : null);

const handleLocationSelected = (location) => {
  myLocation.value = location;
};

const logAlertEvents = (alertArea) => {
  if (alertArea.forecast_level) {
    gtag('event', 'alert_snowForecast', {
      id: myLocation.value.id,
      slug: myLocation.value.slug,
    });
  }

  if (alertArea.report_level) {
    gtag('event', 'alert_snowReport', {
      id: myLocation.value.id,
      slug: myLocation.value.slug,
    });
  }
};

const updateAlertArea = async () => {
  const alertArea = {
    location_id: myLocation.value.id,
    forecast_level: safeRound(myAlertArea.value.forecast_level),
    report_level: safeRound(myAlertArea.value.report_level),
  };

  try {
    await userNotificationsStore.updateAlertArea(alertArea);
    logAlertEvents(alertArea);
    $emit('close');
  }
  catch (e) {
    const { message } = parseOpenMountainApiError(e);
    const { $toast } = useNuxtApp();
    $toast.open({ message, type: 'error' });
  }
};

const removeAlertArea = async () => {
  try {
    await userNotificationsStore.removeAlertArea(props.alertArea.id);
    $emit('close');
  }
  catch (e) {
    const message = parseOpenMountainApiError(e);
    const { $toast } = useNuxtApp();
    $toast.open({ message, type: 'error' });
  }
};
</script>

<template>
  <div>
    <header class="tw-mt-3 tw-mb-4">
      <h2 class="section-title heading-color">
        {{ title }}
      </h2>
    </header>

    <LocationSearch
      v-if="showLocationSearch"
      @update:model-value="handleLocationSelected"
    />
    <div v-else>
      <LocationDetailView
        class="tw-my-2"
        :is-small-image="true"
        :location="myLocation"
      />
      <AlertAreaLevelInput
        v-model="myAlertArea.report_level"
        class="tw-mb-2"
        level="report_level"
        :unavailable-text="unavailableText"
        :units="units"
      />
      <AlertAreaLevelInput
        v-if="isAllAccess"
        v-model="myAlertArea.forecast_level"
        level="forecast_level"
        :units="units"
      />
      <UpgradeToAllAccess
        v-else
        header-text="Create Forecast Alerts with an All-Access subscription!"
        :show-benefits="false"
        :small-padding="true"
      />
      <footer class="tw-flex tw-justify-between tw-mt-4">
        <Button
          :class="updateButtonClass"
          :type="ButtonTypes.primary"
          :disabled="isActionButtonDisabled"
          @click="updateAlertArea"
        >
          {{ isCreateAction ? 'Create Alert' : 'Update Alert' }}
        </Button>
        <Button
          v-if="isUpdateAction"
          :class="deleteButtonClass"
          :type="ButtonTypes.danger"
          @click="removeAlertArea"
        >
          Delete Alert
        </Button>
      </footer>
    </div>
  </div>
</template>
