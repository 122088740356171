<script setup>
import { ButtonTypes } from '@@/components/Common/Button.vue';
import { areArraysDifferent, parseOpenMountainApiError } from '@@/utils/CommonUtils';
import { useDailyReadsStore } from '@@/stores/DailyReads';
import { useUserNotificationsStore } from '@@/stores/UserNotifications';
import cloneDeep from 'lodash.clonedeep';

const { gtag } = useGtag();
const dailyReadsStore = useDailyReadsStore();
const userNotificationsStore = useUserNotificationsStore();

const props = defineProps({
  currentSelectedItems: {
    type: Array,
    default: null,
  },
});

const $emit = defineEmits(['close']);

const items = ref([]);
const itemsById = computed(() => items.value.reduce((acc, item) => {
  acc[item.id] = item;
  return acc;
}, {}));
const selectedItems = ref(props.currentSelectedItems.length
  ? cloneDeep(props.currentSelectedItems)
  : []);
const showLoading = ref(false);

const { data } = await useAsyncData(() => dailyReadsStore.fetchDailyReads({ sort: 'A-Z' }));

if (data.value) {
  items.value = data.value;
}

const buttonText = computed(() => props.currentSelectedItems.length
  ? 'Update Subscriptions'
  : 'Subscribe to Daily Snows');

const selectedCount = computed(() => selectedItems.value.length);

const isActionButtonDisabled = computed(() => {
  if (props.currentSelectedItems.length > 0) {
    const currentSelectedItemIds = props.currentSelectedItems.map(({ id }) => id);
    const selectedItemIds = selectedItems.value.map(({ id }) => id);
    return areArraysDifferent(currentSelectedItemIds, selectedItemIds) ? false : true;
  }

  return selectedItems.value.length === 0;
});

const updateDailySnowNotifications = async () => {
  showLoading.value = true;

  const currentSelectedItemIds = props.currentSelectedItems.map(({ id }) => id);
  const selectedItemIds = selectedItems.value.map(({ id }) => id);

  const itemsToRemove = currentSelectedItemIds.filter((id) => !selectedItemIds.includes(id));
  const itemsToAdd = selectedItemIds.filter((id) => !currentSelectedItemIds.includes(id));

  try {
    for (const id of itemsToRemove) {
      await userNotificationsStore.unsubscribeToDailyRead(id);
    }

    for (const id of itemsToAdd) {
      await userNotificationsStore.subscribeToDailyRead(id);
      const { slug } = itemsById.value?.[id] || {};
      gtag('event', 'alert_dailySnow', { id, slug });
    }
  }
  catch (e) {
    const { message } = parseOpenMountainApiError(e);
    const { $toast } = useNuxtApp();
    $toast.open({ message, type: 'error' });
  }

  showLoading.value = false;
  $emit('close');
};

const updateSelectedItems = (newSelectedItems) => {
  selectedItems.value = newSelectedItems;
};
</script>

<template>
  <div class="tw-relative tw-flex tw-flex-col tw-h-full tw-overflow-hidden">
    <header class="tw-mt-3 tw-mb-4">
      <h2 class="section-title heading-color">
        Select Daily Snow Forecasts ({{ selectedCount }})
      </h2>
    </header>
    <SelectFavoritesList
      :items="items"
      :value="selectedItems"
      view="daily-snows"
      @input="updateSelectedItems"
    />
    <footer class="tw-mt-4">
      <Button
        class="tw-w-full"
        :disabled="isActionButtonDisabled"
        :display-block="true"
        :type="ButtonTypes.primary"
        @click="updateDailySnowNotifications"
      >
        {{ buttonText }}
      </Button>
    </footer>
    <transition
      enter-active-class="fade-enter-transition"
      enter-from-class="fade-out"
      leave-active-class="fade-leave-transition"
      leave-to-class="fade-out"
    >
      <div
        v-if="showLoading"
        :class="[
          'tw-absolute tw-w-full tw-h-full tw-left-0 tw-top-0 tw-z-20',
          $style.loading,
        ]"
      >
        <LoadingIndicator />
      </div>
    </transition>
  </div>
</template>

<style module>
.loading {
  background-color: rgb(var(--card-background-rgb) / 50%);
}
</style>
