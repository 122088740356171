<script setup>
import { useUserStore } from '@@/stores/User';

const userStore = useUserStore();

const props = defineProps({
  alertArea: {
    type: Object,
    default: null,
  },
  action: {
    type: String,
    default: 'create',
    validate(value) {
      return ['create', 'update'].includes(value);
    },
  },
  currentSelectedItems: {
    type: Array,
    default: null,
  },
  location: {
    type: Object,
    default: null,
  },
  showDialog: {
    type: Boolean,
    default: false,
  },

  view: {
    type: String,
    default: 'alertAreas',
    validate(value) {
      return ['alertAreas', 'dailyReads'].includes(value);
    },
  },
});

const $emit = defineEmits(['close']);
const $style = useCssModule();

const isAllAccess = computed(() => userStore.isAllAccess);
const isAlertAreas = computed(() => props.view === 'alertAreas');
const handleClose = (payload) => $emit('close', payload);
const cardClassNames = computed(() => {
  const classNames = [];

  classNames.push($style.container);

  if (!isAllAccess.value) {
    classNames.push($style.containerFreeUser);
  }

  return classNames.join(' ');
});
</script>

<template>
  <Dialog
    :show-dialog="showDialog"
    @close="handleClose"
  >
    <Card
      :card-class-names="cardClassNames"
      :has-body-padding-small="true"
      :has-body-shadow="true"
      :show-close-button="true"
      @close-button-click="handleClose"
    >
      <template #body>
        <ManageAlertArea
          v-if="isAlertAreas"
          :action="action"
          :alert-area="alertArea"
          :location="location"
          @close="handleClose"
        />
        <ManageDailySnowNotifications
          v-else
          :action="action"
          :current-selected-items="currentSelectedItems"
          @close="handleClose"
        />
      </template>
    </Card>
  </Dialog>
</template>

<style module>
.container {
  height: 75vh;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: 90vw;
}

.containerFreeUser {
  height: 80vh;
}

@media (min-width: 992px) {
  .container {
    height: 30rem;
    width: 30rem;
  }

  .containerFreeUser {
    height: 32rem;
  }
}
</style>
