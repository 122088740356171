<script setup>
import { formatSnow, getSnowUnits, safeRound } from '@@/utils/CommonUtils';

const props = defineProps({
  level: {
    type: String,
    default: 'report_level',
    validate(value) {
      return ['forecast_level', 'report_level'].includes(value);
    },
  },
  modelValue: {
    type: Number,
    default: 0,
  },
  unavailableText: {
    type: String,
    default: null,
  },
  units: {
    type: String,
    default: 'imperial',
  },
});

const $emit = defineEmits(['update:model-value']);

const isReportLevel = computed(() => props.level === 'report_level');
const title = computed(() => isReportLevel.value ? 'Report' : 'Forecast');
const subtitle = computed(() => isReportLevel.value ? 'Past 24 Hours' : 'Night + Day');

const max = computed(() => props.units === 'imperial' ? 30 : 75);
const step = computed(() => props.units === 'imperial' ? 1 : 3);

const details = computed(() => isReportLevel.value
  ? 'We will send an alert when the resort reports a 24-hour snow total for a single day that is equal to or greater than this amount.'
  : 'We will send an alert when the night + day forecast for a single day is equal to or greater than this amount.');

const levelFormatted = computed(() => {
  if (props.modelValue === 0) {
    return 'OFF';
  }

  if (props.modelValue >= max.value) {
    // If imperial then display 30+" else display 75+cm
    return `${max.value}+${getSnowUnits(props.units, true)}`;
  }

  return formatSnow(safeRound(props.modelValue), props.units, true);
});
</script>

<template>
  <div class="tw-p-2 tw-rounded-lg tw-border border-color">
    <div class="tw-flex tw-justify-between">
      <div>
        <h4 class="tw-font-bold">
          {{ title }}
        </h4>
        <span class="tw-text-sm text-regular-color">
          {{ subtitle }}
        </span>
      </div>
      <strong
        v-if="!unavailableText"
        :class="modelValue === 0 ? 'text-regular-color' : null"
      >
        {{ levelFormatted }}
      </strong>
    </div>
    <p
      v-if="unavailableText"
      class="tw-pt-6 tw-pb-10 tw-text-center tw-text-sm text-regular-color"
    >
      {{ unavailableText }}
    </p>
    <div v-else>
      <RangeInput
        :full-width="true"
        :max="max"
        :min="0"
        :model-value="modelValue"
        :step="step"
        :use-brand-color="true"
        @update:model-value="(value) => $emit('update:model-value', value)"
      />
      <p class="tw-text-sm text-regular-color">
        {{ details }}
      </p>
    </div>
  </div>
</template>
